<template>
  <div class="page-content">
    <top-menu :setUser="setUser" :user="user" :key="user ? `top-menu-${user._id}` : 'top-menu'"></top-menu>

    <el-row type="flex" justify="center" class="page-body terms-page">

      <el-col :xs="{span: 20}" :sm="{span: 20}" class="content-wrapper about-row">

        <background></background>

        <div style="text-align: left">

          <a href="/" class="btn-back">
            <i class="el-icon-back"></i>
            <span>Back to home</span>
          </a>

          <router-view></router-view>

          <a href="#" class="btn-back" v-if="hasPdf" @click="showPdf">
            <i class="el-icon-document"></i>
            <span>Open pdf</span>
          </a>

          <br>

          <a href="/" class="btn-back">
            <i class="el-icon-back"></i>
            <span>Back to home</span>
          </a>
        </div>
      </el-col>
    </el-row>

    <page-footer :key="'footer'"></page-footer>
  </div>
</template>
<script>
// @ is an alias to /src
import TopMenu from '@/components/top-menu.vue'
import PageFooter from '@/components/page-footer.vue'
import Background from '@/components/background.vue'

export default {
  name: 'CookiesPolicy',
  components: {
    TopMenu,
    PageFooter,
    Background
  },
  mounted () {},

  data () {
    return {
      user: this.$store.state.user,
      hasPdf: ['cookiesPolicy', 'termsOfUse', 'termsOfService', 'termsAndConditions', 'disclaimer', 'eula', 'privacyPolicy'].includes(this.$route.name)
    }
  },

  methods: {
    setUser (user) {
      if (user) {
        this.$store.commit('user', user)
        this.user = user
      } else {
        this.$store.commit('user', false)
        this.user = false
        this.$router.push({ name: 'home', query: { mode: 'login' } })
      }
    },

    showPdf () {
      let pdf

      if (this.$route.name === 'cookiesPolicy') pdf = 'pdfs/cookies-policy.pdf'
      if (this.$route.name === 'termsOfUse') pdf = 'pdfs/terms-of-use.pdf'
      if (this.$route.name === 'termsOfService') pdf = 'pdfs/terms-of-service.pdf'
      if (this.$route.name === 'termsAndConditions') pdf = 'pdfs/terms-and-conditions.pdf'
      if (this.$route.name === 'disclaimer') pdf = 'pdfs/disclaimer.pdf'
      if (this.$route.name === 'eula') pdf = 'pdfs/eula.pdf'
      if (this.$route.name === 'privacyPolicy') pdf = 'pdfs/privacy-policy.pdf'

      if (pdf) window.open(pdf)
      else this.$message.error('Pdf not found...')
    }
  }
}
</script>

<style lang="scss">
  @import '../styles/variables.scss';

  .terms-page {
    line-height: 1.6;

    .btn-back {
      color: $main-color-2;
      line-height: 3;
    }

    a {
      color: $a-ref-color;
    }
  }

  @media screen and (max-width: 61em) {
    .terms-page {
      p {
        line-height: 2;
      }

      .btn-back {
        color: $main-color-2;
        line-height: 4;
      }
    }
  }
</style>
